<template>
<div>
  <Header id="header" />
  <img alt="Profile Pic" id="profile-image" src="../assets/shozzyoctocat_sml.png" />
  <Home msg="Currently in: Perth, W.A." />
  <Footer />
</div>
</template>

<script>
import Home from '../components/Home.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'App',
  components: {
    Home,
    Header,
    Footer
  }
}
</script>


<style scoped>
#header{
  margin-bottom: 15vh;
}
#profile-image {
    border-radius: 37px;
    background: #333333;
    box-shadow: 6px 6px 12px #272727, 
                -6px -6px 12px #3f3f3f;
    width:250px;
    height: 250px;
}
</style>