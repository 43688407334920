<template>
    <div id="footer">
        &copy; Shawn Rego 2014
    </div>
</template>
<script>
export default {
    name:  "Footer"
}
</script>
<style scoped>
#footer{
    background-color:#003366;;
    position:fixed;
    bottom:0%;
    width:100%;
    opacity: 1;
    padding:1vh;
    align-content: left;
}
</style>