<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
     watch: {
       /*eslint-disable no-unused-vars*/
      '$route' (to, from) {
        document.title = to.meta.title || 'Shawn Rego'
      }
      /*eslint-enable no-unused-vars */
    },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  color: #fff;
  margin-top: 60px;
}

body{
  background-color: #333333;
}


#nav {
  padding: 30px;
  display:none;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
