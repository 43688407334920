<template>
  <div>
    <h1>Software Developer</h1>
    <p>{{ msg }}</p>
    <div id="social-icons">	
		<a href="https://twitter.com/shawnrego/" class="social_icon"><i class="fa fa-twitter-square fa-2x"></i></a>							
        <a href="https://www.linkedin.com/pub/shawn-rego/7/342/5b4" class="social_icon"><i class="fa fa-linkedin-square fa-2x"></i></a>
        <a href="https://www.instagram.com/shozzyhops/" class="social_icon"><i class="fa fa-instagram fa-2x"></i></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  },
  data() {
    return {
      count: 0
    }
  }
}
</script>

<style scoped>
  #social-icons a{
    color:#fff;
    margin:5px;
  }
</style>
