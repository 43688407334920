<template>
<div>
    <ul>
        
            <li>{ Programmer }</li>
            <li>{ Lindy Hopper } </li>
            <li>{ Coffee Snob } </li>

    </ul>
</div>
</template>

<script>
export default {
    name:  "Header"
}
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  position: relative;
      font-family: Consolas,"courier new";
  color: #518BAB;
  font-size: 0.75em;
}

</style>